import { Skeleton } from '@nextui-org/react';
import React from 'react';

const JobCardSkeleton:React.FC<{id:string}> = ({id}) => {
  return (
    <div id={id}>
      <Skeleton className="w-full rounded-lg mt-5">
        <div className="h-40 bg-default-300 rounded-lg"></div>
      </Skeleton>
      <Skeleton className="w-full rounded-lg mt-5">
        <div className="h-40 bg-default-300 rounded-lg"></div>
      </Skeleton>
      <Skeleton className="w-full rounded-lg mt-5">
        <div className="h-40 bg-default-300 rounded-lg"></div>
      </Skeleton>
      <Skeleton className="w-full rounded-lg mt-5">
        <div className="h-40 bg-default-300 rounded-lg"></div>
      </Skeleton>
      <Skeleton className="w-full rounded-lg mt-5">
        <div className="h-40 bg-default-300 rounded-lg"></div>
      </Skeleton>
      <Skeleton className="w-full rounded-lg mt-5">
        <div className="h-40 bg-default-300 rounded-lg"></div>
      </Skeleton>
    </div>
  )
}

export default JobCardSkeleton