'use client';
import React, { useEffect, useState } from 'react';
import { Button } from '@nextui-org/react';
import { today, DateValue   } from '@internationalized/date';
import { DatePicker } from '@nextui-org/date-picker';
import { useRouter } from 'next/navigation';

// Custom Components
import BaseButton from '@/components/button/BaseButton';
import TextInput from '@/components/input/TextInput';
import { SearchIcon } from '@/components/icons/search-icon';
import JobCard from '@/components/jobComponents/JobCard';
import MapComponent from '@/components/MapComponent';
import JobCalendar from '@/components/jobComponents/JobCalendar';
import ToggleTabs from '@/components/jobComponents/ToggleTabs';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/redux/store';
import { searchJobs } from '@/redux/thunk/jobScheduleThunk';

// Icons
import { CalendarIcon } from '../../../public/assets/icons/CalendarIcon';
import { ResetIcon } from '@/components/icons/reset-icon';
import { UnavailableLocation } from '../../../public/assets/icons/UnavailableLocation';

// Custom Hooks
import useDebounce from '@/hooks/useDebounce';
import JobCardSkeleton from '@/components/skeleton/JobSchedule/JobCardSkeleton';
import MapSkeleton from '@/components/skeleton/JobSchedule/MapSkeleton';

interface HomePageContentProps {
  id: string
}

const HomePageContent:React.FC<HomePageContentProps> = ({id}) => {
  const dispatch = useDispatch<AppDispatch>();
  const router = useRouter();

  const { jobs } = useSelector((state: RootState) => state.jobSchedule);

  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce<string>(searchTerm, 500);

  const [selectedTab, setSelectedTab] = useState<string>('map');
  const [selectedJob, setSelectedJob] = useState<any>(null);
  const [selectedDate, setSelectedDate] = useState<DateValue | null>(today('UTC'));
  const [loading, setLoading] = useState(true);
  const [buttonWidth, setButtonWidth] = useState('auto');

  useEffect(() => {
    setLoading(true);
    dispatch(
      searchJobs({
        searchTerm: debouncedSearchTerm,
        startDate: selectedDate,
        endDate: selectedDate,
      })
    ).finally(() => setLoading(false));
  }, [debouncedSearchTerm, selectedDate, dispatch]);

  useEffect(() => {
    if (jobs?.length) {
      setSelectedJob(jobs[0]); // Set the first job as the selected job
    }
  }, [jobs]);

  // Dynamically change width based on screen size
  useEffect(() => {
    const updateButtonWidth = () => {
      if (window.innerWidth < 1081) {
        setButtonWidth('100%');  // Full width on small screens
      } else {
        setButtonWidth('141px');  // Fixed width on larger screens
      }
    };

    window.addEventListener('resize', updateButtonWidth);
    updateButtonWidth(); // Initial call to set the width
    return () => window.removeEventListener('resize', updateButtonWidth);
  }, []);

  const handleSearch = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const handleDateChange = (date: DateValue) => {
    setSelectedDate(date)
  };

  const handleReset = () => {
    setSearchTerm('');
    setSelectedDate(today('UTC'))
  };

  const handleJobCardClick = (job: any) => {
    setSelectedJob(job);
  };

  const handleMapLocationSelect = (job: any) => {
    setSelectedJob(job);
  };

  const handleAddNewJob = () => {
    router.push(`/job/new`)
  }

  return (
    <div className="flex flex-col h-[calc(100vh-250px)]">
      <div className="w-full flex flex-col md:flex-row gap-4 h-full">
        {/* Left section */}
        <div className="w-full md:w-3/12 h-full flex flex-col">
          {/* Header and Search */}
          <div className="flex flex-col lg:flex-row justify-between">
            <div className="flex items-center text-body-lg pb-2 lg:pb-0 lg:text-body-xl">
              <div className="pr-2 lg:pr-0">Current Jobs</div>
            </div>
            <div>
              <BaseButton
                id={`${id}-baseButton-addNewJob`}
                variant="bordered"
                color="primary"
                width={buttonWidth}
                onClick={handleAddNewJob}
                size="md"
              >
                <span className="font-bold">+</span> Job
              </BaseButton>
            </div>
          </div>
          <div className="flex w-full pt-4">
            <div className="flex w-full gap-4">
              <div className="flex-1">
                <TextInput
                  id={`${id}-textInput-searchJob`}
                  value={searchTerm}
                  type="text"
                  variant="bordered"
                  label=""
                  radius="sm"
                  style={{ width: '100%' }}
                  placeholder="Search jobs"
                  labelPlacement="outside"
                  onChange={(e) => handleSearch(e)}
                  endContent={
                    <SearchIcon
                      className="text-black/50 mb-0.5 dark:text-white/90 text-slate-400 pointer-events-none flex-shrink-0" />
                  }
                />
              </div>
              <div>
                <Button
                  id={`${id}-baseButton-reset`}
                  isIconOnly
                  variant="bordered"
                  radius="sm"
                  aria-label="Clear Date Range"
                  className="w-10 h-10 border-[#E0E0E0]"
                  onClick={handleReset}
                >
                  <ResetIcon color="#6D788D"></ResetIcon>
                </Button>
              </div>
            </div>
          </div>
          <div className="mt-4 w-full">
            <DatePicker
              id={`${id}-datePicker`}
              value={selectedDate}
              variant={'bordered'}
              aria-placeholder="Date"
              className="w-full flex-1"
              onChange={handleDateChange}
              selectorIcon={<CalendarIcon color="#6D788D" />}
              labelPlacement="outside"
              fullWidth={true}
              color="default"
              size="md"
              radius="sm"
            />
          </div>
          {/* Scrollable Job Cards */}
          <div className="mt-5 overflow-y-auto h-full">
            {loading ? (
              <div className="pr-1">
                <JobCardSkeleton id={`${id}-datePicker`}
                />
              </div>
            ) : jobs?.length ? jobs?.map((job) => (
              <div className="pt-5 pb-2">
                <JobCard
                  id={`${id}-jobCard-${job?.uuid}`}
                  job={job}
                  selectedJobUUID={selectedJob?.uuid}
                  onClick={(job) => handleJobCardClick(job)}
                />
              </div>
            )) : (
              <div className="flex h-full items-center justify-center text-center">
                <p>No Jobs</p>
              </div>
            )}
          </div>
        </div>
        {/* Right section */}
        <div className="w-full md:w-9/12 h-full flex flex-col">
          <div className="pb-4">
            <div className="flex w-full flex-col items-end pr-3">
              <ToggleTabs id={`${id}-toggleTabs`} onTabChange={(key: string) => setSelectedTab(key)} />
            </div>
          </div>
          <div className="border-2 border-light-secondary-100 rounded-xl overflow-hidden flex-1">
            {selectedTab === 'map' ? (
              <div className="h-full">
                {loading ? (
                  <div>
                    <MapSkeleton id={`${id}-mapSkeleton`}/>
                  </div>
                ) : jobs?.length ? (
                  <MapComponent
                    id={`${id}-mapComponent`}
                    jobs={jobs}
                    selectedJob={selectedJob}
                    onLocationSelect={(job) => handleMapLocationSelect(job)}
                  />
                ) : (
                  <div className="flex h-full items-center flex-col justify-center text-center">
                    <UnavailableLocation color="#6D788D" />
                    <p>No Locations to display</p>
                  </div>
                )}

              </div>
            ) : (
              <div>
                <JobCalendar id={`${id}-jobCalender`} jobs={jobs} />
              </div>
            )}
          </div>
        </div>

      </div>
    </div>
  );
};

export default HomePageContent;
