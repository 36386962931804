import React from 'react';
import { Card, CardBody } from '@nextui-org/react';
import NextLink from 'next/link';

// Custom Components
import JobStatusIndicator from '@/components/jobComponents/JobCard/JobStatusIndicator';
import JobCardTeamMembersPreview from '@/components/jobComponents/JobCardTeamMembersPreview';

// Icons
import { MoreArrow } from '../../../../public/assets/icons/MoreArrow';

// Helpers
import { jobCardDateFormatter } from '@/helpers/jobSchedule/jobCardDateFormatter';

// Types
import { Job } from '@/helpers/types';

interface JobCardProps {
  id: string;
  job: Job;
  selectedJobUUID: string;
  onClick: (job: Job) => void;
}

const JobCard:  React.FC<JobCardProps> = ({ id, job, selectedJobUUID, onClick }) => {
  const handleJobCardClick = () => {
    onClick(job);
  };

  return (
    <Card
      id={id}
      radius="md"
      shadow="none"
      className={`mx-1 border-2 ${job?.uuid === selectedJobUUID ? 'border-light-base-primary' : ''}`}
    >
      <CardBody onClick={handleJobCardClick} className="p-2 lg:p-4 cursor-pointer">
        <div className="flex justify-between">
          <JobStatusIndicator id={`${id}-jobStatusIndicator`} status={job?.status} />
          <NextLink style={{ cursor: 'pointer' }} href={`/job/view-job?uuid=${job?.uuid}`}>
            <button
              id={`${id}-button-viewJob`}
              name="viewJob"
              className="bg-transparent border-none"
              style={{ cursor: 'pointer' }}
            >
              <MoreArrow />
            </button>
          </NextLink>
        </div>
        <div className="flex pt-4">
          <div className="text-caption text-light-base-secondary pr-1">
            #{job?.job_number}
          </div>
          <div className="text-caption text-light-base-secondary">|</div>
          <div className="text-caption text-light-base-secondary pl-1">
            {jobCardDateFormatter({ date: '2024-11-19' })}
          </div>
        </div>
        <div className="text-body-sm lg:text-body-xl">{job?.title}</div>
        <div className="pt-1">
          <JobCardTeamMembersPreview id={`${id}-jobCardTeamMembersPreview`} members={job?.assigned_members} />
        </div>
      </CardBody>
    </Card>
  );
};

export default JobCard;
