export const jobCardDateFormatter = ({
  date,
}: {
  date: string | null | undefined;
}): string => {
  if (!date) {
    // Return a default value or handle the null/undefined case
    return 'Invalid date';
  }

  try {
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'short', // Short weekday (e.g., Tue)
      month: 'short', // Short month (e.g., Jan)
      day: 'numeric', // Numeric day (e.g., 7)
      year: 'numeric', // Full year (e.g., 2025)
    };

    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(
      new Date(date),
    );
    return formattedDate;
  } catch (error) {
    // Handle cases where the date is invalid
    console.error('Error formatting date:', error);
    return 'Invalid date';
  }
};
