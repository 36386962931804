import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  Ref,
  RefObject,
} from 'react';
import {
  Input as NextUIInput,
  InputProps as NextUIInputProps,
} from '@nextui-org/react';
import { FieldError, FieldErrorsImpl } from 'react-hook-form';
import { Merge } from 'type-fest';
import { ValidationResult } from '@react-types/shared';

interface TextInputProps {
  id?: string;
  name?: string;
  children?: ReactNode;
  type?: string;
  value?: string | null;
  defaultValue?: string;
  placeholder?: string;
  description?: ReactNode;
  errorMessage?: string | undefined;
  validate?: any;
  validationBehavior?: 'native' | 'aria';
  startContent?: React.ReactNode;
  endContent?: React.ReactNode;
  labelPlacement?: 'outside' | 'inside';
  fullWidth?: boolean;
  isClearable?: boolean;
  isRequired?: boolean;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  isInvalid?: boolean;
  baseRef?: RefObject<HTMLDivElement>;
  validationState?: 'valid' | 'invalid';
  disableAnimation?: boolean;
  className?: string;
  classNames?: Record<string, string>;
  customClassNames?: {
    label?: string;
    input?: string[];
    innerWrapper?: string;
    inputWrapper?: string[];
  };
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onValueChange?: (value: string) => void;
  onClear?: () => void;
  variant?: 'flat' | 'faded' | 'bordered' | 'underlined' | undefined;
  color?:
  | 'default'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning'
  | 'danger'
  | undefined;
  size?: 'md' | 'sm' | 'lg' | undefined;
  radius?: 'md' | 'sm' | 'lg' | 'none' | 'full' | undefined;
  label?: string | undefined;
  style?: React.CSSProperties;
  onFocus?: () => void;
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      id,
      name,
      children,
      type = 'text',
      variant = 'flat',
      color = 'default',
      size = 'md',
      radius = 'sm',
      label,
      style,
      value,
      defaultValue,
      placeholder,
      description,
      errorMessage,
      validate,
      validationBehavior = 'aria',
      startContent,
      endContent,
      labelPlacement,
      fullWidth = true,
      isClearable = false,
      isRequired = false,
      isReadOnly = false,
      isDisabled = false,
      isInvalid = false,
      baseRef,
      validationState,
      disableAnimation = false,
      className,
      classNames,
      customClassNames,
      onChange,
      onValueChange,
      onClear,
      onFocus,
      ...props
    },
    ref: Ref<HTMLInputElement>, // Forwarded ref goes here
  ) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) onChange(e);
      if (onValueChange) onValueChange(e.target.value);
    };

    const mergedClassNames = {
      base: `${classNames?.base || ''}`.trim(),
      input: `${(customClassNames?.input || []).join(' ')}`.trim(),
      label:
        `${classNames?.label || ''} ${customClassNames?.label || ''}`.trim(),
      inputWrapper:
        `${(customClassNames?.inputWrapper || []).join(' ')}`.trim(),
      innerWrapper: `${customClassNames?.innerWrapper || ''}`.trim(),
    };

    return (
      <div className={`relative ${classNames?.container || ''}`}>
        <NextUIInput
          id={id}
          name={name}
          ref={ref} // Pass the forwarded ref here
          type={type}
          startContent={startContent}
          endContent={endContent}
          variant={variant}
          color={color}
          size={size}
          radius={radius}
          label={label}
          style={style}
          value={value === null ? '' : value}
          defaultValue={defaultValue}
          placeholder={placeholder}
          labelPlacement={labelPlacement}
          description={description}
          errorMessage={errorMessage}
          isInvalid={isInvalid}
          isClearable={isClearable}
          isRequired={isRequired}
          isReadOnly={isReadOnly}
          isDisabled={isDisabled}
          baseRef={baseRef}
          validationState={validationState}
          disableAnimation={disableAnimation}
          className={className}
          classNames={mergedClassNames}
          onChange={handleChange}
          onClear={onClear}
          {...props}
          data-invalid={isInvalid}
          data-required={isRequired}
          data-readonly={isReadOnly}
          data-disabled={isDisabled}
          onFocus={onFocus}
          aria-label={!label ? 'Input field' : undefined}
        >
          {children}
        </NextUIInput>
      </div>
    );
  },
);

TextInput.displayName = 'TextInput';

export default TextInput;
