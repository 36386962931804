import React, { useState } from 'react';
import { Button } from '@nextui-org/react';

// Icons
import { CalendarIcon } from '../../../../public/assets/icons/CalendarIcon';
import { MapIcon } from '../../../../public/assets/icons/MapIcon';

interface ToggleTabsProps {
  id: string;
  onTabChange: (activeTab: 'map' | 'calendar') => void;
}

const ToggleTabs: React.FC<ToggleTabsProps> = ({ id, onTabChange }) => {
  const [activeTab, setActiveTab] = useState<'map' | 'calendar'>('map');

  const handleTabChange = (tab: 'map' | 'calendar') => {
    setActiveTab(tab);
    onTabChange(tab); // Notify parent of the change
  };

  return (
    <div id={id} className="flex h-10 items-center bg-white border-2 border-solid border-light-base-primary rounded-md w-fit">
      <Button
        id={`${id}-button-map`}
        size="md"
        className={`flex items-center justify-center w-28 lg:w-[141px] rounded-l-md rounded-r-none transition-all ${
          activeTab === 'map'
            ? 'bg-light-base-primary text-white'
            : 'bg-transparent text-light-base-primary'
        }`}
        onClick={() => handleTabChange('map')}
      >
        <MapIcon color={activeTab === 'map' ? 'white' : '#34C100'} />
        Map
      </Button>
      <Button
        id={`${id}-button-calendar`}
        size="md"
        className={`flex items-center justify-center w-28 lg:w-[141px] rounded-r-md rounded-l-none transition-all ${
          activeTab === 'calendar'
            ? 'bg-light-base-primary text-white'
            : 'bg-transparent text-light-base-primary'
        }`}
        onClick={() => handleTabChange('calendar')}
      >
        <CalendarIcon color={activeTab === 'calendar' ? 'white' : '#34C100'} />
        Calendar
      </Button>
    </div>
  );
};

export default ToggleTabs;
