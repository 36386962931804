export const MapIcon = ({ color = '#34C100' }) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Map">
        <path
          id="Vector"
          d="M13 5V17.5M13 5L18 2.5V15L13 17.5M13 5L8 2.5M13 17.5L8 15M8 15L3 17.5V5L8 2.5M8 15V2.5"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
