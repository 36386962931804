import React from 'react';
import { Chip } from '@nextui-org/react';
import { id } from 'postcss-selector-parser';

interface JobStatusIndicatorProps {
  id: string;
  status: 1 | 2 | 3 | 4 | 5 | number | string; // Numeric representation for statuses
}

const JobStatusIndicator: React.FC<JobStatusIndicatorProps> = ({id, status }) => {
  const statusMap: Record<
    JobStatusIndicatorProps['status'],
    { className: string; text: string }
  > = {
    1: {
      className: 'bg-light-warning-100 text-light-warning-default',
      text: 'Draft',
    },
    2: {
      className: 'bg-light-success-100 text-light-success-default',
      text: 'In Progress',
    },
    3: {
      className: 'bg-blue-100 text-blue-700',
      text: 'Completed',
    },
    4: {
      className: 'bg-light-default-300 text-light-default-700',
      text: 'Closed',
    },
    5: {
      className: 'bg-red-100 text-red-700',
      text: 'Cancelled',
    },
  };

  const statusDetails = statusMap[status];

  if (!statusDetails) {
    return null; // Return nothing if the status is invalid
  }

  const { className, text } = statusDetails;

  return (
      <Chip id={id} className={className} radius="sm" variant="flat" size="sm">
        {text}
      </Chip>
  );
};

export default JobStatusIndicator;
