import React from 'react';
import {
  Button as NextUIButton,
  ButtonProps as NextUIButtonProps,
} from '@nextui-org/react';
import Image from 'next/image';
import {
  StaticImport,
  StaticImageData,
} from 'next/dist/shared/lib/get-img-props';

// Define custom props extending NextUI's ButtonProps
interface BaseButtonProps extends NextUIButtonProps {
  startIcon?: React.ReactNode | string | StaticImport | StaticImageData; // Accept ReactNode for icons or SVGs
  endIcon?: React.ReactNode | string | StaticImport | StaticImageData;
  isIconOnly?: boolean;
  isLoading?: boolean;
  width?: string;
  customStyle?: React.CSSProperties; // Custom style prop
  className?: string;
  disabled?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
}

// The BaseButton component
const BaseButton: React.FC<BaseButtonProps> = ({
  children,
  startIcon,
  endIcon,
  isIconOnly,
  isLoading = false,
  width = 'auto',
  radius = 'sm',
  customStyle = {},
  className = '',
  disabled = false,
  type,
  ...props
}) => {
  const renderIcon = (
    icon: React.ReactNode | string | StaticImport | StaticImageData,
  ) => {
    if (typeof icon === 'string' || typeof icon === 'object') {
      return <Image src={icon as string} alt="Icon" width={24} height={24} />;
    }
    return icon; // For React components like SVGs or other elements
  };

  return (
    <NextUIButton
      {...props}
      className={className || ''}
      disabled={isLoading || disabled}
      isLoading={isLoading}
      radius={radius}
      style={{ width, ...customStyle }} // Apply custom styles here
      type={type}
    >
      <>
        {startIcon && !isIconOnly && renderIcon(startIcon)}
        {!isIconOnly && children}
        {endIcon && !isIconOnly && (
          <span className="ml-2">{renderIcon(endIcon)}</span>
        )}
        {isIconOnly &&
          (startIcon ? renderIcon(startIcon) : renderIcon(endIcon))}
      </>
    </NextUIButton>
  );
};

export default BaseButton;
