import React from 'react';

export const UnavailableLocation = ({ color = '#6D788D' }) => {
  return (
    <svg
      fill={color} // Use the dynamic color prop here
      height="50px"
      width="50px"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 215.152 215.152"
      xmlSpace="preserve"
    >
      <g>
        <path d="M107.573,0C61.849,0,24.649,37.206,24.649,82.938c0,32.665,13.675,65.706,39.545,95.549 c19.32,22.288,38.47,34.917,39.275,35.443c1.246,0.815,2.675,1.222,4.104,1.222s2.857-0.407,4.104-1.222 c0.806-0.526,19.957-13.155,39.278-35.443c25.872-29.844,39.548-62.885,39.548-95.55C190.503,37.206,153.301,0,107.573,0z M107.566,198.447C92.281,187.083,39.649,143.54,39.649,82.938C39.649,45.477,70.12,15,107.573,15 c37.457,0,67.93,30.477,67.93,67.938C175.503,143.401,122.842,187.059,107.566,198.447z" />
        <path d="M130.904,59.592c-2.93-2.928-7.679-2.929-10.606,0l-12.72,12.719L94.857,59.588c-2.929-2.928-7.678-2.929-10.606,0 c-2.93,2.929-2.93,7.678-0.001,10.607l12.721,12.722L84.25,95.636c-2.93,2.929-2.93,7.678-0.001,10.607 c1.465,1.464,3.384,2.197,5.304,2.197c1.919,0,3.839-0.732,5.303-2.196l12.721-12.72l12.721,12.722 c1.465,1.464,3.384,2.197,5.304,2.197c1.919,0,3.839-0.732,5.303-2.196c2.93-2.929,2.93-7.678,0.001-10.607l-12.721-12.722 l12.72-12.718C133.833,67.271,133.833,62.521,130.904,59.592z" />
      </g>
    </svg>
  );
};
