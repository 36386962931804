import React from 'react';

interface Member {
  uuid: string;
  full_name: string;
  image_url: string;
  labor_cost: string;
}

interface TeamMembersProps {
  id: string;
  members: Member[];
}

const JobCardTeamMembersPreview: React.FC<TeamMembersProps> = ({ id, members }) => {
  // Show only the first three members
  const visibleMembers = members?.slice(0, 3);
  const extraMembersCount = members?.length - visibleMembers?.length;

  const getInitials = (fullName: string | null | undefined) => {
    if (!fullName) return ''; // Return an empty string if fullName is null, undefined, or empty

    return fullName
      .split(' ')
      .map((name) => name.charAt(0))
      .join('')
      .toUpperCase();
  };

  return (
    <div id={id} className="flex items-center">
      {visibleMembers?.map((member, index) => (
        <div
          key={member.uuid}
          className={`w-8 border-3 border-white h-8 rounded-full text-caption flex items-center justify-center bg-gray-200 text-gray-600 font-bold p-2 ${
            index > 0 ? '-ml-2' : '' // Apply negative margin for overlap
          }`}
          style={{
            backgroundImage: member?.image_url
              ? `url(${member?.image_url})`
              : undefined,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            zIndex: visibleMembers.length - index,
          }}
        >
          {!member?.image_url && getInitials(member?.full_name)}
        </div>
      ))}

      {extraMembersCount > 0 && (
        <div
          className="-ml-2 w-8 h-8 border-3 border-white rounded-full text-caption flex items-center justify-center text-gray-600 font-bold bg-gray-100">
          +{extraMembersCount}
        </div>
      )}
    </div>
  );
};

export default JobCardTeamMembersPreview;
