'use client';

import React, { useState, useEffect } from 'react';
import {
  DateSelectArg,
  EventClickArg,
} from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useRouter } from 'next/navigation';

// Custom Styles
import Styles from './jobCalender.module.css'

// Types
import { Job } from '@/helpers/types';

interface JobCalendarProps {
  id: string;
  jobs: Job[];
}

interface Event {
  uuid: string;
  title: string;
  job_number: string;
  start_date: string;
  end_date: string;
  start: string;
  end: string;
  type: string;
  status: string;
  total_price: string;
  recurring_type: string;
  customer_name: string;
  customer_site_name: string;
  customer_site_address: string;
  created_at: string;
  backgroundColor: string;
  className: string
  textColor: string;
}

const JobCalendar: React.FC<JobCalendarProps> = ({ id, jobs }) => {
  const router = useRouter()

  const [newEventTitle, setNewEventTitle] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<DateSelectArg | null>(null);
  const [events, setEvents] = useState<Event[]>([]);

  useEffect(() => {
    const transformedJobs = jobs?.map((job) => ({
      ...job,
      start: job.start_date,
      end: job.end_date,
      backgroundColor: handleEventLabelBG(job?.status),
      className: Styles.customEventStyle,
      textColor: handleEventLabelText(job?.status),
    }));
    setEvents(transformedJobs);
  }, [jobs]);

  const handleEventLabelBG = (status: 1 | 2 | 3 | 4 | 5) => {
    const statusMapping = {
      1: '#fdedd3',
      2: '#EEFBE5',
      3: '#dbeafe',
      4: '#d4d4d8',
      5: '#fee2e2',
    };

    // Return the matching status or a default value if status doesn't exist
    return statusMapping[status] || 'bg-gray-100';
  };

  const handleEventLabelText = (status: 1 | 2 | 3 | 4 | 5) => {
    const statusMapping = {
      1: '#FDB528',
      2: '#12A150',
      3: '#1d4ed8',
      4: '#3f3f46',
      5: '#b91c1c',
    };

    // Return the matching status or a default value if status doesn't exist
    return statusMapping[status] || 'text-gray-700';
  };

  const handleDateClick = (selected: DateSelectArg) => {
    setSelectedDate(selected);
  };

  const handleEventClick = (selected: EventClickArg) => {

    if (selected?.event?.extendedProps?.uuid) {
      router.push(`/job/view-job?uuid=${selected?.event?.extendedProps?.uuid}`)
    } else {
      console.error("No job ID available");
    }
  };

  const handleCloseDialog = () => {
    setNewEventTitle('');
  };

  const handleAddEvent = (e: React.FormEvent) => {
    e.preventDefault();
    if (newEventTitle && selectedDate) {
      const calendarApi = selectedDate.view.calendar; // Get the calendar API instance.
      calendarApi.unselect(); // Unselect the date range.

      const newEvent = {
        id: `${selectedDate.start.toISOString()}-${newEventTitle}`,
        title: newEventTitle,
        start: selectedDate.start,
        end: selectedDate.end,
        allDay: selectedDate.allDay,
      };

      calendarApi.addEvent(newEvent);
      handleCloseDialog();
    }
  };

  const renderEventContent = (eventInfo: any) => {
    return (
      <div
        style={{
          display: 'inline-block',
          borderRadius: '8px',
          padding: '2px 6px',
          whiteSpace: 'nowrap',
        }}
      >
        {eventInfo?.event?.title}
      </div>
    );
  }

  return (
    <div id={id}>
      <div className="flex w-full rounded-md">
        <div className="w-full">
          <FullCalendar
            height={'calc(100vh - 308px)'}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]} // Initialize calendar with required plugins.
            headerToolbar={{
              left: 'prev,next,title',
              center: '',
              right: 'dayGridMonth,timeGridWeek,timeGridDay',
            }} // Set header toolbar options.
            initialView="dayGridMonth" // Initial view mode of the calendar.
            editable={true} // Allow events to be edited.
            selectable={true} // Allow dates to be selectable.
            selectMirror={true} // Mirror selections visually.
            dayMaxEvents={true} // Limit the number of events displayed per day.
            select={handleDateClick} // Handle date selection to create new events.
            eventClick={handleEventClick} // Handle clicking on events (e.g., to delete them).
            events={events}
            eventContent={renderEventContent}
          />
        </div>
      </div>
    </div>
  );
};

export default JobCalendar;
